/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import StatusLabel from '@/storychief/components/StatusLabel';
import getCapitalized from '@/storychief/utils/getCapitalized';

const propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  approval_status: PropTypes.string,
};

const defaultProps = {
  id: null,
  onClick: undefined,
  approval_status: null,
};

const fragments = {
  review: gql`
    fragment ApprovalStatusSummary on Review {
      __typename
      id
      accepted_at
      requested_at: created_at
      declined_at
      reviewer_id
    }
  `,
};

function ApprovalStatusSummary({ id, onClick, approval_status }) {
  return approval_status ? (
    <div>
      <StatusLabel id={id} customLabel={getCapitalized(approval_status)} onClick={onClick} />
    </div>
  ) : null;
}

ApprovalStatusSummary.propTypes = propTypes;
ApprovalStatusSummary.defaultProps = defaultProps;
ApprovalStatusSummary.fragments = fragments;

export default ApprovalStatusSummary;
